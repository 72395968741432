// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import App from './App';
import './index.css';

import { AuthProvider } from './components/context/AuthContext';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Crea la raíz


root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
      <Toaster 
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            fontFamily: 'Inter, Arial, sans-serif', // Fuente personalizada
            background: '#363636',
            color: '#fff',
            borderRadius: '8px',
            padding: '12px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Sombra personalizada
            maxWidth: '350px', // Ancho máximo
            wordBreak: 'break-word', // Manejo de texto largo
          },
          // Default options for specific types
          success: {
            style: {
              background: '#4CAF50',
              color: 'white',
              fontFamily: 'Inter, Arial, sans-serif',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            },
            iconTheme: {
              primary: 'white',
              secondary: 'green',
            },
          },
          error: {
            style: {
              background: '#F44336',
              color: 'white',
              fontFamily: 'Inter, Arial, sans-serif',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            },
            iconTheme: {
              primary: 'white',
              secondary: 'red',
            },
          },
        }}
      />
    </AuthProvider>
  </React.StrictMode>
);